.single10 {
  height: 12px;
  width: 12px;
  border: 4px solid rgba(130, 199, 136, 0.5);
  border-radius: 50%;
  position: relative;
  -webkit-animation: single10anim 2s infinite linear;
  animation: single10anim 2s infinite linear;
}

.single10:before {
  content: "";
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #82c788;
  position: absolute;
  top: 8px;
  left: 11px;
}

@-webkit-keyframes single10anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes single10anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
