.btn-submit {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  input[type="submit"] {
    background-color: white;
    color: black;
    border: thin solid;
    font-size: 16px;
    padding: 12px;
    height: 56px;
    border-color: #81c784;
    border-radius: 8px;
    border-width: 2px;
    cursor: pointer;
    margin: 0 24px;
    transition: all ease 0.5s;
    &:hover {
      color: white;
      background-color: #81c784;
    }
    &:focus {
      outline: none;
    }
  }
  .check {
    font-size: 30px;
    color: #81c784;
  }
  .x {
    font-size: 30px;
    color: #81c784;
  }
}
