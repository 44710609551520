nav.nav-component {
  height: 100%;
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0px 24px;
      a {
        text-decoration: none;
        color: #000;
        margin-top: 4px;
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        svg {
          stroke-width: 1;
        }
      }
      &:hover,
      &.active {
        background-color: #81c784;
        a,
        svg {
          color: #fff;
        }
      }
    }
  }
}
