.member-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-member {
  margin-left: 24px;
  cursor: pointer;
}

.org-header {
  width: 100%;
  height: 300px;
  background-position: center center;
  background-size: cover;
}
.meta-inf {
  display: flex;
  .naming {
    flex: 1;
  }
  .org-logo {
    width: 200px;
    height: 200px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
