.list-entry {
  margin: 12px 0px;
  background-color: rgba(0, 0, 0, 0.01);
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: row;
  img {
    width: 150px;
    height: 150px;
  }
  .infos {
    padding: 24px;
    flex: 1;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin: 12px;
      color: #000;
      cursor: pointer;
    }
  }
}
