.imageInput {
  .label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 6px;
    }
  }
  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    flex-direction: column;
  }
  input[type="file"] {
    display: none;
  }
}

.inputField {
  width: 100%;
  height: 56px;
  margin: 6px 0;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s all;
  &.longText {
    min-height: 200px;
    .quill {
      height: 80%;
      margin-bottom: 12px;
    }
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
  input,
  textarea,
  select {
    width: calc(100% - 32px);
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
      0.1s padding ease-in-out;
    -webkit-appearance: none;

    &:-internal-autofill-selected,
    &:input:-webkit-autofill,
    &:input:-webkit-autofill:hover,
    &:input:-webkit-autofill:focus,
    &:textarea:-webkit-autofill,
    &:textarea:-webkit-autofill:hover,
    &:textarea:-webkit-autofill:focus,
    &:select:-webkit-autofill,
    &:select:-webkit-autofill:hover,
    &:select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      background-color: transparent;
    }

    & + label {
      position: absolute;
      top: 24px;
      left: 16px;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      color: #000;
      opacity: 1;
      pointer-events: none;
      transition: 0.1s all ease-in-out;
    }
    &:focus + label,
    &.hasValue + label {
      top: -2px;
    }
    &.hasError + label {
      color: red;
    }

    & ~ span#showPasswordIcon {
      position: absolute;
      top: 12px;
      right: 16px;
      cursor: pointer;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  textarea {
    resize: none;
    height: 100%;
  }
  option {
    width: 100%;
  }
}
