#body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: rgba(33, 33, 33, 0.1);
  position: relative;
}

#auth-form {
  width: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 3s max-height ease-in-out;
  width: 600px;
  margin-top: 30vh;
  img {
    max-width: 320px;
    width: 50%;
    height: auto;
  }
  nav {
    width: 400px;
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      list-style-type: none;
      padding: 0;
      margin: 12px 0;
      li {
        cursor: pointer;
        margin: 0 16px;
        color: rgba(0, 0, 0, 0.3);
        &:first-child {
          margin: 0;
        }
        &:last-child {
          margin-left: auto;
        }
        &.active {
          color: #81c784;
        }
      }
    }
  }
  form {
    width: 400px;
  }
}

#gallery {
  background-image: url("./assets/huellhorst.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    );
  }
  a {
    position: absolute;
    bottom: 24px;
    right: 24px;
    color: white;
    z-index: 3;
  }
}

.error {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
