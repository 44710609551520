footer {
  ul {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    margin-top: 24px 0px 0px 0px;
    border-top: thin solid rgba(149, 149, 149, 0.5);
    li {
      margin: 0 24px;
      a {
        color: #82c788;
        text-decoration: none;
      }
    }
  }
}
