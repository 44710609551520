body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="submit"],
.submit {
  background-color: #81c784;
  color: #f3f3f3;
  border: none;
  font-size: 16px;
  padding: 12px;
  height: 56px;
  border-radius: 4px;
  cursor: pointer;
}
html,
body,
#root {
  height: 100%;
}

main {
  min-height: 100%;
}

.Toastify__toast--success {
  background-color: #81c784 !important;
}
