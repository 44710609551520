header {
  height: 60px;
  background-color: rgba(0, 0, 0, 0.02);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  #app-logo {
    height: 100%;
    width: auto;
  }
}
