.title {
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  sub {
    font-size: 14px;
    font-weight: 300;
    margin-top: 6px;
  }
}
.create-action {
  margin: 12px;
  color: #000;
}
