.container {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.flex {
  display: flex;
  height: 100%;
  width: 100%;
}

.shadowbox {
  margin: 12px 0px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.01);
  width: calc(100% - 24px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
